










































































































































































































































































































import { defineComponent, ref, useContext, useFetch, useRouter } from '@nuxtjs/composition-api';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { digits, email, min, required } from 'vee-validate/dist/rules';
import SvgImage from '~/components/General/SvgImage';
import ContentBlocks from '~/components/ContentBlocks';
import { useConfig, useContact, useContent as useCmsContent } from '~/composables';
import { SfButton, SfDivider, SfInput, SfLoader, SfSelect, SfTextarea, } from '@storefront-ui/vue';
import { MetaInfo } from 'vue-meta';
import PictimeContactModal from '~/components/molecules/PictimeContactModal.vue';
import useUiState from '~/composables/useUiState/index.ts';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('digits', {
  ...digits,
  message: 'Invalid format'
});

export default defineComponent({
  name: 'ContactForm',
  components: {
    PictimeContactModal,
    SfButton,
    SfDivider,
    SfInput,
    SfLoader,
    ValidationProvider,
    ValidationObserver,
    SfSelect,
    SfTextarea,
    SvgImage,
    ContentBlocks
  },
  setup() {
    const select = ref('');
    const { config } = useConfig();
    const { sendContact } = useContact();
    const { app } = useContext();
    const router = useRouter();
    const { loadBlocks: loadCms } = useCmsContent();
    const blocks = ref([]);
    const { toggleContactModal, isContactModalOpen } = useUiState();

    const questionList = [
      'Aide en ligne / site',
      'Duplicata de facture',
      'Commande en cours',
      'Problème de livraison',
      'Retours et remboursements',
      'Données personnelles',
    ];

    const motifList = ref([]);
    motifList.value = config.value?.contact_general_reason;

    type Form = {
      objet: string,
      num_commande: string,
      motif: string,
      nom: string,
      prenom: string,
      mobile: string,
      email_contact: string,
      code_postal: string,
      message: string,
    };

    const form = ref<Form>({
      objet: '',
      num_commande: '',
      motif: '',
      nom: '',
      prenom: '',
      mobile: '',
      email_contact: '',
      code_postal: '',
      message: '',
    });

    const subFormContact = (resetValidationFn: () => void) => async () => {
      resetValidationFn();

      const result = await sendContact(form.value);

      if (result?.data?.sendContactEmail) {
        await router.push(app.localeRoute({ name: 'ContactConfirmation' }));
      }
    };

    useFetch(async () => {
      blocks.value = await loadCms({ identifiers: ['contact-form-rules'] });
    })

    return {
      blocks,
      questionList,
      motifList,
      select,
      form,
      subFormContact,
      toggleContactModal,
      isContactModalOpen
    }
  },
  head(): MetaInfo {
    const meta = [];
    const title = 'Contactez-nous';

    meta.push({
      hid: 'description',
      name: 'description',
      content: this.$t('contact_meta_description')
    });

    return {
      title,
      meta
    }
  }
});
